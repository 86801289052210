<template>
  <div
    class="d-flex flex-column flex-grow-1"
    v-if="document.id"
  >
    <document-toolbar
      :tab="tab"
      :links.sync="document.links"
      :show-link-manager="!documentIsNew"
      @close-pop-up="refreshCache()"
      @update-links="refreshCache()"
      v-on="$listeners"
    >
    </document-toolbar>
    <document-in-tab>
      <v-card class="mb-6">
        <v-card-title>
          {{$t('t.Informations')}}
        </v-card-title>
        <v-card-text>
          <grid item-min-width="300px">
            <v-text-field
              readonly
              :label="$t('t.Decision')"
              v-model="decisionText"
            />
            <amount-field
              class="mr-1"
              :label="$t('t.StandingCreditLimit')"
              :currency.sync="groupamaCurrency"
              :amount="document.standingCreditLimit"
              readonly
            />
            <amount-temporary-field
              class="mr-1"
              :label="$t('t.TemporaryCreditLimit')"
              :currency="groupamaCurrency"
              :amount="document.temporaryCreditLimit"
              :start-date="document.decisionStartExceeding"
              :end-date="document.decisionEndExceeding"
              readonly
            />
          </grid>
          <grid item-min-width="300px">
            <v-text-field
              :label="$t('t.RequestDate')"
              :value="formatDate(document.requestDate)"
              readonly
            />
            <amount-field
              class="mr-1"
              :label="$t('t.StandingAmount')"
              :currency="groupamaCurrency"
              :amount="document.standingAmount"
              readonly
            />
            <amount-temporary-field
              class="mr-1"
              :label="$t('t.TemporaryAmount')"
              :currency="groupamaCurrency"
              :amount="document.temporaryAmount"
              :start-date="document.startExceeding"
              :end-date="document.endExceeding"
              readonly
            />
          </grid>
          <v-text-field
            readonly
            :label="$t('t.Comment')"
            v-model="document.comment"
          />
        </v-card-text>
      </v-card>
    </document-in-tab>
  </div>
</template>

<script>

import DocumentEditor from '@/mixins/document-editor'

export default {
  mixins: [DocumentEditor],
  components: {
    AmountField: () => import('@/components/amount-field'),
    AmountTemporaryField: () => import('@/components/amount-temporary-field'),
    DocumentInTab: () => import('@/components/document-in-tab'),
    DocumentToolbar: () => import('@/components/document-toolbar'),
    Grid: () => import('@/components/grid')

  },
  async created () {
    Object.assign(this.tab, { cancel: this.cancel })
  },
  data () {
    return {
    }
  },
  computed: {
    decisionCodeLibelle () {
      return this.document?.decisionCode ? this.$t(`t.${this.document.decisionCode.toUpperCase()}`) : ''
    },
    decisionText () {
      return this.document?.decisionDate ? this.decisionCodeLibelle + ' ' + this.$t('t.At') + ' ' + this.formatDate(this.document.decisionDate) : ''
    },
    groupamaCurrency () {
      return 'EUR'
    },
    cacheType () {
      return DocumentEditor.CacheType.GroupamaRequestDetail
    },
    id () {
      return this.tab.documentId
    }
  },
  methods: {
    async refreshDocumentHook () {
      if (!this.document?.id) { return }
      this.$emit('document-title-change', { tab: this.tab, value: `${this.tab.typeName} ${this.document.id}` })
      this.$emit('document-ready', this.tab)
    },
    cancel () {
      this.$removeFromKeepAliveCache()
      this.$destroy()
    }
  },
  props: {
    tab: Object
  }
}
</script>

<style lang="stylus" scoped></style>
